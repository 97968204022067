

import React,{Component} from 'react';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import slide1 from '../../Assets/Images/where-1.png';
import slide2 from '../../Assets/Images/where-2.png'; 
import axios from 'axios';
import Layout from '../Layout/Layout';
import * as constants from '../Global/Global';
import Popup from './Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
toast.configure();
localStorage.clear(); // Clear local storage
sessionStorage.clear(); // Clear session storage 
const recaptchaRef = React.createRef();
class Home extends Component{
  constructor(props) {
    super(props);
    this.state = {
    invoice: '',
    amount: 0,
    payment_plan:'',
    validForm:false,
    validFullForm:false,
    validRecForm:false,
    isActiveFull:false,
    isActiveRec:false,
    isActiveOther:false,
    isReadOnly:"",
    formType:"",
    validOtherForm:false,
    invoiceValid: false,
    amountValid: false,
    showInvoiceHelp:false,
    showInspectionInvoiceHelp:false,
    alert:'',
    status:'',
    showErr:'',
    redirect:false,
    showHide:false,
    remBalance: 0.00,
    minPayAmt: 0.00,
    inspectionInvoiceDetails:{}
  };
  // Set axios headers to prevent caching
  //axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
  //axios.defaults.headers.common['Pragma'] = 'no-cache';
  //axios.defaults.headers.common['Expires'] = '0';
}

calculateMinPaymentAmount = (total) => {
    let minimum_payment_amount = 25.00;
    if ( total < 2500 ) {
      minimum_payment_amount	= (Number(total)/6).toFixed(2);
    } else if ( total >= 2500 && total < 10000 ) {
      minimum_payment_amount	= (Number(total)/12).toFixed(2);
    } else if ( total >= 10000 ) {
      minimum_payment_amount	= (Number(total)/24).toFixed(2);
    }
    return 1 * minimum_payment_amount;
}

calculateRecMinPaymentAmount = (total) => {
  let minimum_payment_amount = 25.00;
  if ( total < 2500 ) {
    minimum_payment_amount	= (Number(total)/6);
  } else if ( total >= 2500 && total < 10000 ) {
    minimum_payment_amount	= (Number(total)/12);
  } else if ( total >= 10000 ) {
    minimum_payment_amount	= (Number(total)/24);
  }

  if ((minimum_payment_amount * 100) % 1 !== 0) {
    // Increment the last digit by 0.01
    const thirdDigit = ((minimum_payment_amount * 1000) % 10);
    if (thirdDigit < 5) {      
      minimum_payment_amount += 0.01;
      console.log("After less than 5:"+minimum_payment_amount); 
    } 
    
    console.log("Before:"+minimum_payment_amount);    
    minimum_payment_amount = minimum_payment_amount.toFixed(2);
    console.log("After:"+minimum_payment_amount);
  }

  return 1 * minimum_payment_amount;
}

/*
This will handle the submit Button Click
*/
handleOk = (event)=>{

  const recaptchaValue = recaptchaRef.current.getValue();
  if(!recaptchaValue)
  {
    toast.error('Please check the the captcha form.');
    return false;
  } else if(this.state.formType=="")
  {
    toast.error('Please choose an option to do the payment.');
    return false;
  }

  
  event.preventDefault();
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount = this.state.amount;

  amount = encodeURI(amount);

  let amount_invoiced = 0.00;
  let remainingBalance = 0.00;
  this.setState({validForm: false});
  //Fetch the claim details
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/0')
  .then((response)=> {
    
    const invoice_type = response.data.invoice_type;
    if(invoice_type == 1)
    {
      const res = response.data.result[0];
      //console.log(res);
      if( res.length !== 0)
      amount_invoiced = Number(res.amount_invoiced);

     // console.log('amount_invoiced:'+amount_invoiced);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);

      //console.log('remainingBalance:'+remainingBalance);
      let min_payment_amount = this.calculateMinPaymentAmount(amount_invoiced);

      if ( remainingBalance < min_payment_amount ) {
        min_payment_amount = remainingBalance;
      }

       // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
       let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

       if(rem_total <= 1) {
         min_payment_amount = Number(min_payment_amount) + Number(rem_total);
       }
       
       min_payment_amount = (1*min_payment_amount).toFixed(2);
       //console.log('rem_total:'+rem_total);
      //console.log('min_payment_amount:'+min_payment_amount);
      if( !res )
      {          
          toast.error('No claims found for this invoice number and amount.');
          recaptchaRef.current.reset(); 
      }
      else if(res.status =='Payment Received')
      {
        recaptchaRef.current.reset();   
        toast.error('The specified invoice is already paid.');  

      }
      else if (res.status === 'Ready for Collections') {
          alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
          recaptchaRef.current.reset(); 
      }
        else if( res.amount_invoiced ===0)
      {              
            //toast.error('The amount invoiced for this claim is zero.');
            this.setState({showErr: "The amount invoiced for this claim is zero."});
            recaptchaRef.current.reset(); 
      }
      else if( this.state.amount <= 0)
      {              
            //toast.error('The amount invoiced for this claim is zero.');
            this.setState({showErr: "Please enter an amount greater than  zero for the payment."});
            recaptchaRef.current.reset(); 
      }
      /*else if( amount_invoiced !==  this.state.amount)
      {
        console.log( amount_invoiced);
        console.log( this.state.amount);
        recaptchaRef.current.reset(); 

              //alert('The actual amount is Full amount'+res[0].amount_invoiced);
            this.setState({alert: 'The actual amount invoiced for this claim is '+res[0].amount_invoiced});
            //console.log();
            //alert( );
              toast.error('We do not accept partial payments. Please enter the full amount shown on your invoice.'); 
            this.setState({redirect: false  });
      }*/
     /* else if ( this.state.amount < min_payment_amount && this.state.formType != "full") {
         //toast.error(`Please enter an amount greater than $${( 1 * min_payment_amount ).toFixed(2)} for the partial payment.`);
         //alert("amount:"+this.state.amount);alert("min:"+min_payment_amount);
        this.setState({showErr:`Please enter an amount greater than $${( 1 * min_payment_amount ).toFixed(2)} for the partial payment.`}); 
        this.setState({redirect: false  });
      }*/
      else if ( this.state.amount > amount_invoiced && Number(res.amount_collected) == 0 ) {
        //toast.error(`Please enter an amount less than or equal to the invoiced amount $${amount_invoiced} for the payment.`);
        this.setState({showErr:`Please enter an amount less than or equal to the invoiced amount $${amount_invoiced} for the payment.`}); 
        this.setState({redirect: false  });
      } else if ( parseFloat(this.state.amount) > parseFloat(remainingBalance) && Number(res.amount_collected) > 0 ) {
        //toast.error(`Please enter an amount less than or equal to the remaining balance $${remainingBalance} for the payment.`); 
        this.setState({showErr:`Please enter an amount less than or equal to the remaining balance $${remainingBalance} for the payment.`});
        this.setState({redirect: false  });
      }      
      else {
        this.setState({showErr:""});
        this.setState({redirect: true});
        if (this.state.redirect) {
          //return <Redirect to={`./paymentmethods/${this.state.invoice}`} />
          //this.props.history.push('./paymentmethods/claim/'+this.state.invoice);
          this.props.history.push({
            pathname: '/paymentmethods/claim/'+this.state.invoice,          
            state: { payment_amount: this.state.amount, payment_plan: this.state.payment_plan }
          }); 

        }
      }           
    } else {      
          toast.error('Currently, Partial payments are not allowed for Inspections. We will implement this feature soon.');
          recaptchaRef.current.reset();
          return false;
      const res = response.data.result;
      amount_invoiced = Number(res.invoice_total);
      let actual_invoiced_amount = Number(res.actual_invoiced_amount);
      let min_payment_amount = this.calculateMinPaymentAmount(actual_invoiced_amount);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
      if ( remainingBalance < min_payment_amount ) {
        min_payment_amount = remainingBalance;
      }
       // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
       let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

       if(rem_total <= 1) {
         min_payment_amount = min_payment_amount + rem_total;
       }      
      if(!res)
      {
        recaptchaRef.current.reset(); 
        toast.error('No Inspection/Claim invoice found for this invoice number and amount.');  
      }
      else if(res.status=='Paid')
      {
      recaptchaRef.current.reset();   
      toast.error('The specified invoice is already paid.');  

      }
      else if ( this.state.amount < min_payment_amount && this.state.formType != "full") { //alert(1);
        //toast.error(`Please enter an amount greater than $${(1 * min_payment_amount).toFixed(2)} for the partial payment.`);
        this.setState({showErr:`Please enter an amount greater than $${(1 * min_payment_amount).toFixed(2)} for the partial payment.`}); 
        this.setState({redirect: false  });
      }
      else if ( this.state.amount > actual_invoiced_amount && Number(res.amount_collected) == 0 ) {//alert(2);
        //toast.error(`Please enter an amount less than or equal to the invoiced amount $${actual_invoiced_amount} for the payment.`);
        this.setState({showErr:`Please enter an amount less than or equal to the invoiced amount $${actual_invoiced_amount} for the payment.`}); 
        this.setState({redirect: false  });
      } else if ( parseFloat(this.state.amount) > parseFloat(remainingBalance) && Number(res.amount_collected) > 0) { //alert(3);
        //toast.error(`Please enter an amount less than or equal to the remaining balance $${remainingBalance} for the payment.`);
        this.setState({showErr:`Please enter an amount less than or equal to the remaining balance $${remainingBalance} for the payment.`});
        this.setState({redirect: false  });
      }
      else
      { //alert(4);
        this.setState({showErr:""});
        this.setState({inspectionInvoiceDetails: {...res}}); //Set the ajax request to state variable
        this.handleModalShowHide();///Show invoice popup
        toast.success('Please confirm the selected Inspection Invoice.');  
        recaptchaRef.current.reset(); 
      }
    }          
    this.setState({remBalance: remainingBalance});   
    this.setState({validForm: true});
  })
  .catch(function (error) {
    //console.log(error);
     toast.error(error.message);  
  });
}

/*
This will handle the focus out of invoice number input
*/
handleInvoiceDet = (event)=>{
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount_invoiced = 0;
  let remainingBalance = 0.00;
  const value = event.target.value;
  let amount = this.state.amount;
  amount = encodeURI(amount);  
  this.setState({validForm: false});
  this.setState({validFullForm: false});  
  this.setState({validOtherForm: false});
  this.setState({validRecForm: false});
  this.setState({isActiveFull: false});
  this.setState({isActiveRec: false});
  this.setState({isActiveOther: false});
  this.setState({amount : 0});
  const cacheBuster = new Date().getTime();   
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/0?cb='+cacheBuster).then((response)=> {
    const invoice_type = response.data.invoice_type;    
    if(invoice_type == 1)
    {
        const res = response.data.result[0];
        const check_invoice = response.data.check_invoice;
        const check_subscription = response.data.check_subscription;
        //console.log(res);
        if( !res )
        {         
            toast.error('No claims found for this invoice number and amount.');
            recaptchaRef.current.reset();
            this.setState({remBalance: 0.00});
            this.setState({minPayAmt: 0.00}); 
        }
        else if(res.status =='Payment Received')
        {
          recaptchaRef.current.reset();   
          toast.error('The specified invoice is already paid.');  
          this.setState({remBalance: 0.00});
          this.setState({minPayAmt: 0.00}); 
        }
        else if (res.status === 'Ready for Collections') {
            alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
            recaptchaRef.current.reset();
            this.setState({remBalance: 0.00});
            this.setState({minPayAmt: 0.00}); 
        }
        else if( res.amount_invoiced ===0)
        {            
              toast.error('The amount invoiced for this claim is zero.');            
              recaptchaRef.current.reset();
              this.setState({remBalance: 0.00});
              this.setState({minPayAmt: 0.00}); 
        }        
        else if( check_subscription > 0 && (res.dept_id == 1173 || res.dept_id == 2627) ) {
          recaptchaRef.current.reset();   
          toast.error('A subscription has been started for this invoice. So payment is not permitted for the invoice.');  
          this.setState({remBalance: 0.00});
          this.setState({minPayAmt: 0.00}); 
        } else {        
              if( res.length !== 0)
                amount_invoiced = Number(res.amount_invoiced);

              //console.log('amount_invoiced:'+amount_invoiced);
              //console.log('amount_collected:'+res.amount_collected);
              ////console.log('remainingBalance:'+remainingBalance);
              // console.log('amount_invoiced:'+amount_invoiced);
              remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
              
              let min_payment_amount = this.calculateMinPaymentAmount(amount_invoiced);             

              if ( remainingBalance < min_payment_amount ) {
                min_payment_amount = remainingBalance;
              }
                // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
              let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

              if(rem_total <= 1) {
                min_payment_amount = min_payment_amount + rem_total;
              }
              min_payment_amount = (1*min_payment_amount).toFixed(2);
              //console.log('remainingBalance:'+remainingBalance);
              if(remainingBalance > 0)
                remainingBalance = remainingBalance;
              else
                remainingBalance = 0.00;
              
              if(min_payment_amount > 0)
                min_payment_amount = min_payment_amount;
              else
                min_payment_amount = 0.00;  

                remainingBalance = parseFloat(remainingBalance);
                this.setState({remBalance: (1 * remainingBalance).toFixed(2)});
                //this.setState({minPayAmt: (1 * min_payment_amount ).toFixed(2)});;
                this.setState({validFullForm: true});  
                this.setState({validOtherForm: true}); 
                this.setState({validRecForm: true});   
            }            
      } else {          
          toast.error('Currently, Partial payments are not allowed for Inspections. We will implement this feature soon.');
          recaptchaRef.current.reset();
          return false;

          const res = response.data.result[0];
          if( !res )
          {          
              toast.error('No claims found for this invoice number and amount.');
              recaptchaRef.current.reset();
              this.setState({remBalance: 0.00});
              this.setState({minPayAmt: 0.00}); 
          }
          else if(res.status =='Payment Received')
          {
            recaptchaRef.current.reset();   
            toast.error('The specified invoice is already paid.');
            this.setState({remBalance: 0.00});
            this.setState({minPayAmt: 0.00});
          }
          else if (res.status === 'Ready for Collections') 
          {
              alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
              recaptchaRef.current.reset();
              this.setState({remBalance: 0.00});
              this.setState({minPayAmt: 0.00}); 
          }
            else if( res.amount_invoiced ===0)
          {                 
             toast.error('The amount invoiced for this claim is zero.');            
             recaptchaRef.current.reset();
             this.setState({remBalance: 0.00});
            this.setState({minPayAmt: 0.00}); 
          } else {          
              amount_invoiced = Number(res.invoice_total);
              let actual_invoiced_amount = Number(res.actual_invoiced_amount);
              let min_payment_amount = this.calculateMinPaymentAmount(actual_invoiced_amount);
              remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
              if ( remainingBalance < min_payment_amount ) {
                min_payment_amount = remainingBalance;
              }
              remainingBalance = parseFloat(remainingBalance);

               // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
               let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

               if(rem_total <= 1) {
                 min_payment_amount = min_payment_amount + rem_total;
               }

            // console.log('min_payment_amount:'+min_payment_amount);
              this.setState({remBalance: (1 * remainingBalance).toFixed(2)});
              //this.setState({minPayAmt: (1 * min_payment_amount ).toFixed(2)});
              this.setState({validFullForm: true});  
              this.setState({validOtherForm: true});              
        }      
      }
   })
  .catch(function (error) {
    //console.log(error);
     toast.error(error.message);  
  });  
}
  handleVerify = ()=> {  //alert(5);
        this.setState({ showHide: false });
        // this.props.history.push('./paymentmethods/inspection/'+this.state.inspectionInvoiceDetails.id); 
        this.setState({redirect: true});
        this.props.history.push({
          pathname: '/paymentmethods/inspection/' + this.state.inspectionInvoiceDetails.id,          
          state: { payment_amount: this.state.amount }
        });        
    }

   handleModalShowHide = ()=> { 
        this.setState({ showHide: !this.state.showHide }) 
    }
  /*
  This will handle the help button click
  */
  handleInvoiceHelp = (event)=>{
       let showInvoiceHelp = this.state.showInvoiceHelp;
       this.setState({showInvoiceHelp: !showInvoiceHelp});
  }
  /*
  This will handle the form field validation
  */
  /*
  This will handle the help button click
  */
  handleInspectionInvoiceHelp = (event)=>{
       let showInspectionInvoiceHelp = this.state.showInspectionInvoiceHelp;
       this.setState({showInspectionInvoiceHelp: !showInspectionInvoiceHelp});
  }
  /*
  This will handle the form field validation
  */
  validateField(fieldName, value) {

    let invoiceValid = this.state.invoiceValid;
    let amountValid = this.state.amountValid;

    switch(fieldName) {
      case 'invoice':
        invoiceValid = (value==='') ? false : true;
        break;
      case 'amount':
        amountValid = (value==='') ? false : true;
        break;
      default:
        break;
    }
    this.setState({invoiceValid: invoiceValid, amountValid: amountValid}, this.validateForm);
  }
  /*
  This will handle the callback from  form field validation
  */
  validateForm = () =>
  {
    this.setState({validForm: this.state.amountValid && this.state.invoiceValid});
    //this.setState({validForm:this.state.invoiceValid});  // For inspection only the invocie number is required
  }
  /*
  This will handle the form inputs change event
  */
  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({[name]:  e.target.name === 'amount' ? e.target.valueAsNumber : value},
                  () => { this.validateField(name, value) });            
  }
  onChange = (value) => {
  //console.log("Captcha value:", value);
}

handleKeyDown = (e) => {
  // Allow backspace if the input field is readonly
  if (e.key === 'Backspace' && e.target.readOnly) {
    const updatedValue = this.state.amount.slice(0, -1); // Remove the last character from the value
    this.setState({amount : updatedValue}); // Update the input value
    e.preventDefault(); // Prevent the default backspace behavior
  }
};

/*
This will handle the payment full button click Click
*/
handleFull = (event)=>{
  
  const recaptchaValue = recaptchaRef.current.getValue();
 
  let amount_invoiced = 0.00;
  let remainingBalance = 0.00;
  /*if(!recaptchaValue)
  {
    toast.error('Please check the the captcha form.');
    return false;
  }*/
  event.preventDefault();
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount = this.state.amount;

  amount = encodeURI(amount);  
  this.setState({validFullForm: false});
  this.setState({validOtherForm: true});
  this.setState({validRecForm: true});
  this.setState({isActiveFull: false});
  this.setState({isActiveRec: false});
  this.setState({isActiveOther: false});
  this.setState({isReadOnly: ""});
  const isReadOnly = "";
  //Fetch the claim details
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/0')
  .then((response)=> {
    
    const invoice_type = response.data.invoice_type;
    if(invoice_type == 1)
    {
      const res = response.data.result[0];
     // console.log(res);
      if( res.length !== 0)
      amount_invoiced = Number(res.amount_invoiced);

      //console.log('amount_invoiced:'+amount_invoiced);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);

      //console.log('remainingBalance:'+remainingBalance);
      
      if( !res )
      {          
          toast.error('No claims found for this invoice number and amount.');
          recaptchaRef.current.reset(); 
      }
      else if(res.status =='Payment Received')
      {
        recaptchaRef.current.reset();   
        toast.error('The specified invoice is already paid.');  

      }
      else if (res.status === 'Ready for Collections') {
          alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
          recaptchaRef.current.reset(); 
      }
      else if( res.amount_invoiced ===0)
      {              
            //toast.error('The amount invoiced for this claim is zero.');
            this.setState({showErr: "The amount invoiced for this claim is zero."});
            recaptchaRef.current.reset(); 
      }
      else {
        this.setState({validFullForm: true});
        this.setState({validOtherForm: true});
        this.setState({validRecForm: true});
        this.setState({isActiveFull: true});
        this.setState({isActiveRec: false});
        this.setState({isActiveOther: false});
        this.setState({validForm: true});
        this.setState({isReadOnly: true});
        this.setState({amount : remainingBalance});
        this.setState({formType : "full"});
        this.setState({payment_plan: ""});
        //recaptchaRef.current.reset();
      }           
    } else {
      toast.error('Currently, Partial payments are not allowed for Inspections. We will implement this feature soon.');
          recaptchaRef.current.reset();
          return false;
      const res = response.data.result;     
      amount_invoiced = Number(res.invoice_total);
      let actual_invoiced_amount = Number(res.actual_invoiced_amount); 
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);     
      if(!res)
      {
        recaptchaRef.current.reset(); 
        toast.error('No Inspection/Claim invoice found for this invoice number and amount.');  
      }
      else if(res.status=='Paid')
      {
      recaptchaRef.current.reset();   
      toast.error('The specified invoice is already paid.');  

      }      
      else
      { 
        this.setState({validFullForm: true});
        this.setState({validForm: true});
        this.setState({validOtherForm: true});
        this.setState({isReadOnly: true});
        this.setState({amount : remainingBalance});
        this.setState({formType : "full"});
        //recaptchaRef.current.reset(); 
      }
    }        
   
  })
  .catch(function (error) {
    //console.log(error);
     toast.error(error.message);  
  });
}

/*
This will handle the payment other button click Click
*/
handleOther = (event)=>{
  
  const recaptchaValue = recaptchaRef.current.getValue();
 
  let amount_invoiced = 0.00;
  let remainingBalance = 0.00;
  /*if(!recaptchaValue)
  {
    toast.error('Please check the the captcha form.');
    return false;
  }*/
  event.preventDefault();
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount = this.state.amount;

  amount = encodeURI(amount);  
  this.setState({validFullForm: true});  
  this.setState({validOtherForm: false});
  this.setState({validRecForm: true});
  this.setState({isActiveFull: false});
  this.setState({isActiveRec: false});
  this.setState({isActiveOther: false});
  this.setState({isReadOnly: ""});
  const isReadOnly = "";
  //Fetch the claim details
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/0')
  .then((response)=> {
    
    const invoice_type = response.data.invoice_type;
    if(invoice_type == 1)
    {
      const res = response.data.result[0];
      //console.log(res);
      if( res.length !== 0)
      amount_invoiced = Number(res.amount_invoiced);

      //console.log('amount_invoiced:'+amount_invoiced);
     // remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);

      //console.log('remainingBalance:'+remainingBalance);
      let min_payment_amount = this.calculateMinPaymentAmount(amount_invoiced).toFixed(2);
      //console.log('min_payment_amount:'+min_payment_amount);
      if ( parseFloat(remainingBalance) < parseFloat(min_payment_amount) ) {
        min_payment_amount = remainingBalance;
      }
      // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
      let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

      if(rem_total <= 1) {
        min_payment_amount = Number(min_payment_amount) + Number(rem_total);
      }     
      min_payment_amount = (1*min_payment_amount).toFixed(2);
      //console.log('remainingBalance:'+remainingBalance);
      //console.log('min_payment_amount:'+min_payment_amount);
      //console.log('rem_total:'+rem_total);
      
      if( !res )
      {          
          toast.error('No claims found for this invoice number and amount.');
          recaptchaRef.current.reset(); 
      }
      else if(res.status =='Payment Received')
      {
        recaptchaRef.current.reset();   
        toast.error('The specified invoice is already paid.');  

      }
      else if (res.status === 'Ready for Collections') {
          alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
          recaptchaRef.current.reset(); 
      }
      else if( res.amount_invoiced ===0)
      {              
            //toast.error('The amount invoiced for this claim is zero.');
            this.setState({showErr: "The amount invoiced for this claim is zero."});
            recaptchaRef.current.reset(); 
      }
      else {
        min_payment_amount = (1*min_payment_amount).toFixed(2);
        this.setState({validFullForm: true});
        this.setState({validOtherForm: true});
        this.setState({validRecForm: true});
        this.setState({isActiveFull: false});
        this.setState({isActiveRec: false});
        this.setState({isActiveOther: true});
        this.setState({validForm: true});
        this.setState({isReadOnly: ""});
        this.setState({amount : min_payment_amount});
        this.setState({formType : "partial"});
        this.setState({payment_plan: ""});
        //recaptchaRef.current.reset();
      }           
    } else {
      toast.error('Currently, Partial payments are not allowed for Inspections. We will implement this feature soon.');
          recaptchaRef.current.reset();
          return false;
      const res = response.data.result;     
      amount_invoiced = Number(res.invoice_total);
      let actual_invoiced_amount = Number(res.actual_invoiced_amount);
      let min_payment_amount = this.calculateMinPaymentAmount(actual_invoiced_amount);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
      if ( parseFloat(remainingBalance) < parseFloat(min_payment_amount) ) {
          min_payment_amount = remainingBalance;
      }
      min_payment_amount = (1*min_payment_amount).toFixed(2); 
      //console.log('remainingBalance 2:'+remainingBalance);
      //console.log('min_payment_amount 2:'+min_payment_amount);     
      if(!res)
      {
        recaptchaRef.current.reset(); 
        toast.error('No Inspection/Claim invoice found for this invoice number and amount.');  
      }
      else if(res.status=='Paid')
      {
      recaptchaRef.current.reset();   
      toast.error('The specified invoice is already paid.');  

      }      
      else
      { 
        min_payment_amount = (1*min_payment_amount).toFixed(2);
        this.setState({validFullForm: true});
        this.setState({validOtherForm: true});
        this.setState({validForm: true});
        this.setState({isReadOnly: ""});
        this.setState({amount : min_payment_amount});
        this.setState({formType : "partial"});
        //recaptchaRef.current.reset(); 
      }
    }        
   
  })
  .catch(function (error) {
    //console.log(error);
     toast.error(error.message);  
  });
}
//else if( res.dept_id != 1173 || res.dept_id != 2627){
/*
This will handle the payment recuring button click 
*/
handleRecurring = (event)=>{
  
  const recaptchaValue = recaptchaRef.current.getValue();
 
  let amount_invoiced = 0.00;
  let remainingBalance = 0.00;
  /*if(!recaptchaValue)
  {
    toast.error('Please check the the captcha form.');
    return false;
  }*/
  event.preventDefault();
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount = this.state.amount;

  amount = encodeURI(amount);  
  this.setState({validFullForm: true});  
  this.setState({validOtherForm: true});
  this.setState({validRecForm: false});
  this.setState({isActiveFull: false});
  this.setState({isActiveRec: false});
  this.setState({isActiveOther: false});
  this.setState({isReadOnly: ""});
  this.setState({payment_plan: ""});
  const isReadOnly = "";
  //Fetch the claim details
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/0')
  .then((response)=> {
    
    const invoice_type = response.data.invoice_type;
    if(invoice_type == 1)
    {
      const res = response.data.result[0];
      //console.log(res);
      if( res.length !== 0)
      amount_invoiced = Number(res.amount_invoiced);

      //console.log('amount_invoiced:'+amount_invoiced);
     // remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);

      //console.log('remainingBalance:'+remainingBalance);
      let min_payment_amount = this.calculateRecMinPaymentAmount(amount_invoiced).toFixed(2);
      console.log("after func:"+min_payment_amount);
      //console.log('min_payment_amount:'+min_payment_amount);
      if ( parseFloat(remainingBalance) < parseFloat(min_payment_amount) ) {
        min_payment_amount = remainingBalance;
      }
      // if remaining payment amount is less than 1 dollar we adding this to the minimum payment amount
      let rem_total = parseFloat(amount_invoiced) - (Number(res.amount_collected) + parseFloat(min_payment_amount));

      if(rem_total <= 1) {
        min_payment_amount = Number(min_payment_amount) + Number(rem_total);
      }     
      min_payment_amount = (1*min_payment_amount).toFixed(2);
      //console.log('remainingBalance:'+remainingBalance);
      //console.log('min_payment_amount:'+min_payment_amount);
      //console.log('rem_total:'+rem_total);
      
      if( !res )
      {          
          toast.error('No claims found for this invoice number and amount.');
          recaptchaRef.current.reset(); 
      }
      else if(res.status =='Payment Received')
      {
        recaptchaRef.current.reset();   
        toast.error('The specified invoice is already paid.');  

      }
      else if (res.status === 'Ready for Collections') {
          alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
          recaptchaRef.current.reset(); 
      }
      else if( res.amount_invoiced ===0)
      {              
            //toast.error('The amount invoiced for this claim is zero.');
            this.setState({showErr: "The amount invoiced for this claim is zero."});
            recaptchaRef.current.reset(); 
      }       
      else if (res.dept_id !== 1173 && res.dept_id !== 2627) {         
        toast.error('Currently, subscription payments are not allowed for this department. We will implement this feature soon..');  
        this.setState({remBalance: 0.00});
        this.setState({minPayAmt: 0.00});
        this.setState({validForm: false});
        this.setState({isActiveRec: true});
        this.setState({validRecForm: true}); 
        recaptchaRef.current.reset(); 
      }
      else {
        console.log("Just Before:"+min_payment_amount);
        min_payment_amount = (1*min_payment_amount).toFixed(2);
        this.setState({validFullForm: true});
        this.setState({validOtherForm: true});
        this.setState({validRecForm: true});
        this.setState({isActiveFull: false});
        this.setState({isActiveRec: true});
        this.setState({isActiveOther: false});
        this.setState({validForm: true});
        this.setState({isReadOnly: true});
        this.setState({amount : min_payment_amount});
        this.setState({formType : "rec"});
        this.setState({payment_plan: "ARB"});
        //recaptchaRef.current.reset();
      }           
    } else {
      toast.error('Currently, Partial payments are not allowed for Inspections. We will implement this feature soon.');
          recaptchaRef.current.reset();
          return false;
      const res = response.data.result;     
      amount_invoiced = Number(res.invoice_total);
      let actual_invoiced_amount = Number(res.actual_invoiced_amount);
      let min_payment_amount = this.calculateMinPaymentAmount(actual_invoiced_amount);
      remainingBalance = (amount_invoiced - Number(res.amount_collected)).toFixed(2);
      if ( parseFloat(remainingBalance) < parseFloat(min_payment_amount) ) {
          min_payment_amount = remainingBalance;
      }
      min_payment_amount = (1*min_payment_amount).toFixed(2); 
      //console.log('remainingBalance 2:'+remainingBalance);
      //console.log('min_payment_amount 2:'+min_payment_amount);     
      if(!res)
      {
        recaptchaRef.current.reset(); 
        toast.error('No Inspection/Claim invoice found for this invoice number and amount.');  
      }
      else if(res.status=='Paid')
      {
      recaptchaRef.current.reset();   
      toast.error('The specified invoice is already paid.');  

      }      
      else
      { 
        min_payment_amount = (1*min_payment_amount).toFixed(2);
        this.setState({validFullForm: true});
        this.setState({validOtherForm: true});
        this.setState({validForm: true});
        this.setState({isReadOnly: true});
        this.setState({amount : min_payment_amount});
        this.setState({formType : "partial"});
        //recaptchaRef.current.reset(); 
      }
    }        
   
  })
  .catch(function (error) {
    //console.log(error);
     toast.error(error.message);  
  });
}
/*

  /*
  This will handle the JSX Rendering
  */
  render ()
  {
        return (
          <Layout>
            <div className="custom-container">
            <div className="payment-wrap">
            <Popup handleVerify={this.handleVerify} partialAmountToPay={this.state.amount} inspectionInvoiceDetails={this.state.inspectionInvoiceDetails} handleModalShowHide={this.handleModalShowHide} showHide={this.state.showHide} />
                        <form  onSubmit={e => {           e.preventDefault();         }}    action="/">
                          <div className="logo"><img src={big_logo} alt="" /></div>
                          <h3>Make a Payment</h3>                        
                          <div class="invoice-field clearfix">
                            <div class="lable-sec">
                              <h4>Please Enter your Invoice number</h4>
                              </div>
                              <div class="input-sec">
                              <input className="form-control"  onBlur={(event) => this.handleInvoiceDet(event)} onChange={(event) => this.handleUserInput(event)} name="invoice" type="text" />
                                  </div>
                              </div>
                          <div class="invoice-field clearfix">
                            <span class="invoicetoggle" style={{paddingTop: "10px", fontWeight: "bold"}}>Your statement balance is ${(1*this.state.remBalance).toFixed(2)}. Please select from one of the 3 options below</span>
                          </div>
                          <div class="invoice-field clearfix">
                            <button type="button" disabled={!this.state.validFullForm} className={`btn-pay ${this.state.isActiveFull ? 'active' : ''}`} onClick={this.handleFull}>Pay Statement in Full</button><br/>
                            <button type="button"  className={`btn-pay ${this.state.isActiveRec ? 'active' : ''}`} id={this.state.isActive}  disabled={!this.state.validRecForm}  onClick={this.handleRecurring}>Set up Recurring Minimum Payments</button><br/>
                            <button type="button" className={`btn-pay ${this.state.isActiveOther ? 'active' : ''}`} disabled={!this.state.validOtherForm}  onClick={this.handleOther}>Pay Other Amount</button>
                          </div>
                          <div className="invoice-field clearfix">
                            <div className="lable-sec">
                               
                              {this.state.showInvoiceHelp? '-' : '+'}<a href="#" id="showfield" onClick={this.handleInvoiceHelp}>Help me find my claim invoice number</a>
                              <div   className={!this.state.showInvoiceHelp? 'd-none invoicetoggle' : 'invoicetoggle'}   >
                              <img src = {slide1}/> 
                            </div> 
                              <br/>{this.state.showInspectionInvoiceHelp? '-' : '+'}<a href="#" id="showfield" onClick={this.handleInspectionInvoiceHelp}> Help me find my inspection invoice number</a>
                           
                              <div   className={!this.state.showInspectionInvoiceHelp? 'd-none invoicetoggle' : 'invoicetoggle'}   >
                            <img src = {slide2}/> 
                          </div>
                            </div>
                             
                          </div>
                        
                          <div className="amount-field">
                          <div className="amount-form"><label>Payment Amount</label><input className="form-control" readOnly={this.state.isReadOnly}  onKeyDown={(event) => this.handleKeyDown(event)} onChange={(event) => this.handleUserInput(event)} value={this.state.amount} name="amount" type="number" />
                          <div id="amtError" style={{color: "#E53030",fontWeight: "bold", fontSize: "12px" }}>{this.state.showErr}</div></div>
                          <div className="invoice-field clearfix">
                          <div className="input-sec" style={{marginBottom: "15px"}}>

                          <ReCAPTCHA    ref={recaptchaRef} sitekey={constants.RECAPCHAKEY}    onChange={this.onChange}/>  
                            
                          </div> 
                          </div> 
                            <button type="button" className="btn-submit"  disabled={!this.state.validForm}   onClick={this.handleOk}>SUBMIT</button>
                          </div>
                          
                          </form>
            </div>
          </div>
        </Layout>
        );

  };
}
export default Home;
